import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    container: {
        padding: 20,
        flex: 1,
    },
    searchContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 40,
        maxWidth: 580,
        margin: 20,
    },
    searchInput: {
        flex: 1,
        height: 40,
        width: '100%',
        borderWidth: 1,
        borderRadius: 5,
        paddingHorizontal: 10,
    },
    paginationContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    paginationButtons: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    paginationButton: {
        padding: 10,
        marginLeft: 5,
    },
    controlsContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    paginationInfo: {
        marginBottom: 10,
    },
});

export default styles;