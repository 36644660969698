import '@expo/match-media'
import React from 'react';
import { NavigationContainer, DefaultTheme, createNavigationContainerRef } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { Text, View, TouchableHighlight } from 'react-native';
import HomeScreen from './screens/HomeScreen.web';
import PrivacyPolicyScreen from './screens/PrivacyPolicyScreen.web'
import Logo from './svgs/Logo';
import { useMediaQuery } from "react-responsive";

import { useFonts } from 'expo-font';
import DashboardNavigator from './navigators/DashboardNavigator.web';
import Footer from './components/Footer';
import Constants from 'expo-constants';
import { OpenAPI } from './apis/foodsmarts';

OpenAPI.BASE = Constants.expoConfig?.extra?.apiUrl
OpenAPI.WITH_CREDENTIALS = true
OpenAPI.CREDENTIALS = 'include'

const linking = {
  prefixes: [],
  enabled: true,
  config: {
    screens: {
      initialRouteName: 'Home',
      Home: {
        path: '',
      },
      Dashboard: {
        path: 'dashboard',
        screens: {
          Login: 'login',
          Home: 'home',
          Users: 'users',
          Warehouses: 'warehouses',
          FoodProducts: 'food-products',
          Foods: 'foods',
          Annotations: 'annotations',
          Recipes: 'recipes',
          Orders: 'orders',
        }
      },
      Privacy: 'privacy',
    },
  }
};


export type RootStackParamList = {
  Home: undefined;
  Dashboard: {
    magic?: string
  };
  Privacy: undefined;
};

const RootStack = createNativeStackNavigator<RootStackParamList>();
const navigationRef = createNavigationContainerRef<RootStackParamList>()


export default function App() {
  const isTiny = useMediaQuery({
    maxWidth: 400
  });

  useFonts({
    'Anton': require('./assets/fonts/Anton-Regular.ttf'),
  });

  const MyTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      background: 'white'
    },
  };

  const header = () => (
    <View style={{ flex: 1, alignItems: 'center', marginVertical: 40 }}>
      <TouchableHighlight underlayColor={'undefined'} onPress={() => { navigationRef.navigate('Home') }}>
        <Logo />
      </TouchableHighlight>
    </View>
  );

  return (
    <View style={{ flexGrow: 1, flex: 1, backgroundColor: 'green' }}>
      <NavigationContainer
        ref={navigationRef}
        linking={linking}
        fallback={<Text>Loading...</Text>}
        theme={MyTheme}
      >
        <RootStack.Navigator
          screenLayout={({ children, route, navigation }) => (
            <View style={{ flexGrow: 1, flex: 1 }}>
              {children}
              {(route.name != 'Dashboard') && <Footer navigation={navigation} isTiny={isTiny} />}
            </View>
          )}
          screenOptions={{
            title: 'Food Smarts'
          }}
        >
          <RootStack.Screen
            name="Home"
            options={{
              header
            }}
            component={HomeScreen}
          />
          <RootStack.Screen
            name="Dashboard"
            component={DashboardNavigator}
          />
          <RootStack.Screen
            name="Privacy"
            options={{
              headerTitle: 'Privacy policy'
            }}
            component={PrivacyPolicyScreen}
          />
        </RootStack.Navigator>
      </NavigationContainer>
    </View>
  );
}
