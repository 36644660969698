import { createNativeStackNavigator, NativeStackHeaderProps, NativeStackScreenProps } from '@react-navigation/native-stack';
import LoginScreen from "../screens/dashboard/LoginScreen.web"
import HomeScreen from "../screens/dashboard/HomeScreen.web"
import UsersScreen from '../screens/dashboard/UsersScreen.web';
import React, { useEffect, useState } from "react"
import { View, Text, ActivityIndicator, TouchableOpacity, StyleSheet } from "react-native";
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { DashboardRootStackParamList } from "../types"
import Footer from '../components/Footer';
import { useMediaQuery } from 'react-responsive';
import { RootStackParamList } from '../App.web';
import { LoginService } from '../apis/foodsmarts';
import { NavigationProp } from '@react-navigation/native';
import { useNavigation } from '@react-navigation/native';

// Define the menu item type
type MenuItem = {
    name: keyof DashboardRootStackParamList;
    icon: keyof typeof MaterialCommunityIcons.glyphMap;
    label: string;
};

// Define the menu structure
const menuItems: MenuItem[] = [
    { name: 'Home', icon: 'view-dashboard-outline', label: 'Home' },
    { name: 'Users', icon: 'account-multiple-outline', label: 'Users' },
    { name: 'Warehouses', icon: 'warehouse', label: 'Warehouses' },
    { name: 'FoodProducts', icon: 'food-outline', label: 'Food Products' },
    { name: 'Foods', icon: 'food-apple-outline', label: 'Foods' },
    { name: 'Annotations', icon: 'label-multiple-outline', label: 'Annotations' },
    { name: 'Recipes', icon: 'notebook-outline', label: 'Recipes' },
    { name: 'Orders', icon: 'package-variant-closed', label: 'Orders' },
];

// Create a reusable NavItem component
const NavItem: React.FC<{
    item: MenuItem;
    navigation: NavigationProp<DashboardRootStackParamList>;
    isActive: boolean;
}> = ({ item, navigation, isActive }) => (
    <TouchableOpacity
        onPress={() => navigation.navigate(item.name)}
        style={[
            styles.navItem,
            isActive && styles.activeNavItem,
        ]}
    >
        <MaterialCommunityIcons name={item.icon} size={24} style={styles.navIcon} />
        <Text>{item.label}</Text>
    </TouchableOpacity>
);

type Props = NativeStackScreenProps<RootStackParamList, 'Dashboard'>;
export default function DashboardNavigator({ route }: Props) {
    const [loading, setLoading] = React.useState(true)
    const [loggedIn, setLoggedIn] = React.useState(false)
    const [sidebarVisible, setSidebarVisible] = useState(true)

    useEffect(() => {
        if (route.params?.magic) {
            LoginService.validateMagicLinkLoginClaimPost({ requestBody: { claim: route.params.magic } })
                .then(() => {
                    setLoading(false)
                    setLoggedIn(true)
                })
        } else {
            LoginService.refreshTokenLoginRefreshPost()
                .then(() => {
                    setLoading(false)
                    setLoggedIn(true)
                })
                .catch(() => {
                    setLoading(false)
                    setLoggedIn(false)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [])

    const Stack = createNativeStackNavigator<DashboardRootStackParamList>()

    const isTiny = useMediaQuery({
        maxWidth: 400
    });

    const toggleSidebar = () => setSidebarVisible(!sidebarVisible)

    const navigation = useNavigation();

    const CustomHeader = (props: NativeStackHeaderProps & { children: React.ReactNode }) => (
        <View style={[styles.headerContainer, sidebarVisible && loggedIn&& styles.activeHeaderContainer]}>
            <Text style={styles.headerTitle}>{props.children}</Text>
            {loggedIn && (
                <TouchableOpacity onPress={toggleSidebar} style={styles.headerButton}>
                    <View style={[styles.iconContainer]}>
                        <MaterialCommunityIcons
                            name="menu"
                            size={24}
                            style={[styles.icon, sidebarVisible && styles.activeIcon]}
                        />
                    </View>
                </TouchableOpacity>
            )}
        </View>
    );

    React.useLayoutEffect(() => {
        navigation.setOptions({
            headerTitle: (props: NativeStackHeaderProps & { children: React.ReactNode }) => <CustomHeader {...props} />,
            headerRight: () => null, // Remove the previous headerRight
        });
    }, [navigation, sidebarVisible, loggedIn]);

    if (loading) {
        return (
            <View style={styles.loadingContainer}>
                <Text style={styles.loadingText}>Loading</Text>
                <ActivityIndicator size="large" />
            </View>
        )
    } else {
        return (
            <Stack.Navigator
                screenLayout={({ children, navigation, route }) => (
                    <View style={styles.container}>
                        <View style={styles.content}>
                            {loggedIn && sidebarVisible && (
                                <View style={styles.sidebar}>
                                    <View style={styles.menu}>
                                        {menuItems.map((item) => (
                                            <NavItem
                                                key={item.name}
                                                item={item}
                                                navigation={navigation}
                                                isActive={route.name === item.name}
                                            />
                                        ))}
                                    </View>
                                </View>
                            )}
                            {children}
                        </View>
                        <Footer navigation={navigation} isTiny={isTiny} />
                    </View>
                )}
            >
                {!loggedIn ? (
                    <Stack.Screen
                        name="Login"
                        options={{
                            title: 'Dashboard | Login',
                            header: () => null,
                        }}
                        component={LoginScreen}
                    />
                ) : (
                    <>
                        <Stack.Screen
                            name="Home"
                            options={{
                                title: 'Dashboard | Home',
                                header: () => null,
                            }}
                            component={HomeScreen}
                        />
                        <Stack.Screen
                            name="Users"
                            options={{
                                title: 'Dashboard | Users',
                                header: () => null,
                            }}
                            component={UsersScreen}
                        />
                        <Stack.Screen
                            name="Warehouses"
                            options={{
                                title: 'Dashboard | Warehouses',
                                header: () => null,
                            }}
                            component={UsersScreen}
                        />
                        <Stack.Screen
                            name="FoodProducts"
                            options={{
                                title: 'Dashboard | Food Products',
                                header: () => null,
                            }}
                            component={UsersScreen}
                        />
                        <Stack.Screen
                            name="Foods"
                            options={{
                                title: 'Dashboard | Foods',
                                header: () => null,
                            }}
                            component={UsersScreen}
                        />
                        <Stack.Screen
                            name="Annotations"
                            options={{
                                title: 'Dashboard | Annotations',
                                header: () => null,
                            }}
                            component={UsersScreen}
                        />
                        <Stack.Screen
                            name="Recipes"
                            options={{
                                title: 'Dashboard | Recipes',
                                header: () => null,
                            }}
                            component={UsersScreen}
                        />
                        <Stack.Screen
                            name="Orders"
                            options={{
                                title: 'Dashboard | Orders',
                                header: () => null,
                            }}
                            component={UsersScreen}
                        />
                    </>
                )}
            </Stack.Navigator>
        )
    }
}

// Define styles
const styles = StyleSheet.create({
    container: { flexGrow: 1, flex: 1, flexDirection: 'column' },
    content: { flexGrow: 1, flexDirection: 'row' },
    sidebar: { width: 250, borderRightWidth: 1, borderColor: 'lightgrey' },
    menu: { flexDirection: 'column', alignContent: 'center', justifyContent: 'center' },
    navItem: { flexDirection: 'row', alignItems: 'center', borderWidth: 2, borderColor: 'transparent' },
    activeNavItem: { borderColor: '#d81e05' },
    navIcon: { margin: 15 },
    loadingContainer: { flex: 1, justifyContent: 'center', alignItems: 'center' },
    loadingText: { fontSize: 30 },
    headerContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 234,
        borderRightWidth: 1,
        borderColor: 'transparent',
        marginHorizontal: 16,
        position: 'absolute',
        left: -16,
        top: 0,
        bottom: 0,
    },
    headerTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        marginRight: 10,
    },
    headerButton: {
        padding: 5,
    },
    iconContainer: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    activeHeaderContainer: {
        borderColor: 'lightgrey',
    },
    icon: {
        color: 'black',
    },
    activeIcon: {
        fontWeight: 'bold',
    },
});
