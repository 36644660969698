import React from 'react';
import { View, Text, TextInput, TouchableOpacity } from "react-native";
import styles from './UsersScreen.styles';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import HeroButton from '../../components/HeroButton';

export default function UsersScreen() {
    return (
        <View style={styles.container}>
            <View style={styles.controlsContainer}>
                <View>
                    <HeroButton
                        title='Create'
                        style={{
                            marginRight: 10,
                            width: 100,
                            height: 48,
                            borderRadius: 5
                        }}
                        onPress={() => {
                        }}
                    />
                </View>
                <View style={styles.searchContainer}>
                    <TextInput
                        style={styles.searchInput}
                        placeholder="Search"
                    />
                </View>
                <View style={styles.paginationButtons}>
                    <Text>Showing 1-10 of 100</Text>
                    <TouchableOpacity style={styles.paginationButton}>
                        <MaterialCommunityIcons name="chevron-left" size={24} color="black" />
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.paginationButton}>
                        <MaterialCommunityIcons name="chevron-right" size={24} color="black" />
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    );
}